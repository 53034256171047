<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <Instructions></Instructions>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Instructions from '@components/agriculture-main/admin/instructions'
export default {
  page: {
    title: '',
  },
  components: {
    Instructions,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
